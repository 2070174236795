<template>
  <el-drawer :title="formTitle" size="50%" :visible.sync="formVisible">
    <el-form
      :model="dataForm"
      ref="dataForm"
      label-width="120px"
      :rules="formRules"
    >
    <el-form-item label="显示序号:" prop="Order">
        <el-input-number v-model="dataForm.Order"/>
      </el-form-item>
      <el-form-item label="测试项目:" prop="CheckType">
        <enumSelector2
          ref="enumSelector"
          :options="$config.checkType"
          :clearable="false"
          size="small"
          v-model="dataForm.CheckType"
        />
      </el-form-item>
      <el-form-item label="处方类型:" prop="PrescType">
        <enumSelector2
          ref="enumSelector"
          :options="$config.prescType"
          :clearable="false"
          size="small"
          v-model="dataForm.PrescType"
        />
      </el-form-item>
      <el-form-item label="测试分值≤:" prop="LessEqualScore">
        <el-input-number v-model="dataForm.LessEqualScore"></el-input-number>
      </el-form-item>
      <el-divider>运动提升计划</el-divider>
      <el-form-item label="训练类别:" prop="ActionWay">
        <el-input
          v-model="dataForm.ActionWay"
          auto-complete="off"
          placeholder="请输入训练类别"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="提升内容:" prop="ActionModule">
        <el-input
          v-model="dataForm.ActionModule"
          auto-complete="off"
          placeholder="请输入强化提升内容"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="负荷强度:" prop="ActionLoad">
        <el-input
          v-model="dataForm.ActionLoad"
          auto-complete="off"
          placeholder="请输入负荷强度"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="训练器材:" prop="Equipment">
        <el-input
          v-model="dataForm.Equipment"
          auto-complete="off"
          placeholder="请输入训练器材"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="运动频次:" prop="ActionFrequency">
        <el-input
          v-model="dataForm.ActionFrequency"
          auto-complete="off"
          placeholder="请输入训练周期及频次"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="时间要求:" prop="TimeMin">
        <el-input-number
          v-model="dataForm.TimeMin"
          style="width: 40%"
          auto-complete="off"
          placeholder="分钟"
          :disabled="readOnly"
        ></el-input-number>分
        <el-input-number
          v-model="dataForm.TimeSec"
          style="width: 40%"
          auto-complete="off"
          placeholder="秒"
          :disabled="readOnly"
        ></el-input-number>秒
      </el-form-item>
      <el-form-item label="备注:" prop="Desc">
        <el-input
          v-model="dataForm.Desc"
          auto-complete="off"
          placeholder="请输入备注"
          :disabled="readOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="示例图片:" prop="Image">
        <img
          v-if="dataForm.Image"
          :src="baseUrl + dataForm.Image"
          :key="dataForm.Image"
          style="height: 100px"
        />
        <el-button
          class="submit-btn"
          type="warning"
          size="mini"
          @click="selectFile"
          >点此上传</el-button
        >
      </el-form-item>
      <el-form-item v-if="!readOnly">
        <div class="footer">
          <el-button type="primary" @click="handleSubmit">提交</el-button>
        </div>
      </el-form-item>
    </el-form>
    <fileUploadNoUI ref="fileUploadNoUI" @uploadSuccess="uploadSuccess" />
  </el-drawer>
</template>
<script>
import { save } from "@/api/library/prescriptionType";
import enumSelector2 from "@/components/enumSelector2";
import fileUploadNoUI from "@/components/fileUploadNoUI";
export default {
  components: {
    enumSelector2,
    fileUploadNoUI,
  },
  props: {},
  data() {
    return {
      readOnly: false,
      formVisible: false,
      formTitle: "新增",
      dataForm: {},
      formRules: {
      Order: [
          { required: true, message: "请输入输入显示序号", trigger: "change" },
        ],
        CheckType: [
          { required: true, message: "请选择适用测试项目", trigger: "change" },
        ],
        PrescType: [
          { required: true, message: "请选择处方类型", trigger: "change" },
        ],
        Equipment: [
          { required: true, message: "请输入训练器材", trigger: "change" },
        ],
        LessEqualScore: [
          { required: true, message: "请输入触发分数", trigger: "change" },
        ],
        ActionModule: [
          { required: true, message: "请输入强化提升内容", trigger: "change" },
        ],
        ActionWay: [
          { required: true, message: "请输入训练方式", trigger: "change" },
        ],
        ActionFrequency: [
          { required: true, message: "请输入训练频次", trigger: "change" },
        ],
        ActionLoad: [
          { required: true, message: "请输入负荷强度", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    selectFile() {
      this.$refs.fileUploadNoUI.chooseFile();
    },
    uploadSuccess(val) {
      this.dataForm.Image = val.Path;
    },
    handleView(row) {
      this.readOnly = true;
      this.dataForm = JSON.parse(JSON.stringify(row));
      this.formTitle = "查看";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleCreate() {
      this.formTitle = "新增";
      this.formVisible = true;
      this.readOnly = false;
      this.dataForm = {};
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.dataForm = JSON.parse(JSON.stringify(row));
      this.readOnly = false;
      this.formTitle = "修改";
      this.formVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleSubmit: function () {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let data = Object.assign({}, this.dataForm);
          console.log(data);
          save(data).then((response) => {
            if (response) {
              this.formVisible = false;
              this.$emit("update");
            }
          });
        } else {
          setTimeout(() => {
            this.$refs["dataForm"].clearValidate();
          }, 1000);
        }
      });
    },
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
/deep/.el-drawer__header {
  margin-bottom: 10px;
  font-size: 16px;
  color: #ff862f;
}

/deep/.el-drawer__body {
  overflow-y: scroll;
}

.footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.invoice-container {
  padding: 10px;

  .input {
    width: 150px;
  }

  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }

    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>