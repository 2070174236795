import request from '@/utils/request'


export function save(data) {
  return request({
    url: 'prescription/save',
    method: 'post',
    data
  })
}

export function deleteObj(data) {
  return request({
    url: 'prescription/delete',
    method: 'post',
    data
  })
}

export function getPageList(data) {
  return request({
    url: 'prescription/getPageList',
    method: 'post',
    data
  })
}
